import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import spesifisitet from "../../../static/img/spesifisitet.png";
import bolgeAccent from "../../../static/img/bolge-accent.png";
import eksempeldesign from "../../../static/img/eksempeldesign.png";
export const _frontmatter = {
  "title": "Semantiske farger",
  "order": 1,
  "template": "default",
  "introtext": "Semantiske farger defineres og navngis basert på hvordan de brukes, ikke hvordan de ser ut."
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Semantiske farger i vårt designsystem`}</h2>
    <p>{`Ved å bruke semantiske farger har vi standardisert fargebruk i våre digitale flater. Siden fargene har en fast betydning knyttet til bruksområde, uavhengig av fargetone, bruker vi navn som surface-default og border-default. Dette sikrer konsistent bruk på tvers av systemet.`}</p>
    <img src={spesifisitet} alt="fargene blir mer spesifikke ved bruk av semantiske farger" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Navnestruktur`}</h2>
    <p>{`Hver semantiske farge består av tre deler: UI-element, variant og tilstand (state).`}</p>
    <h3>{`UI-element (hva fargen brukes på)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`UI-element`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Forklaring`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Background`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bakgrunn på hele siden.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Surface`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Større overflater som kort, modaler eller popover.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mindre flater som badges og chips.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Foreground`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tekst og ikoner.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Border`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rammer og skillelinjer.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Variant (hvilken funksjon fargen har)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Variant`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Forklaring`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Primary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brukes til de mest fremtredende komponentene og handlingene i UI-et.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Secondary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brukes til mindre viktige komponenter og handlinger som støtter primærinnholdet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tertiary`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Brukes til handlinger med lavere prioritet, som alternative valg.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Info`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Formidler tilleggsinformasjon uten å kreve umiddelbar handling.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indikerer at en handling er fullført eller at brukerinput er korrekt.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Warning`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Varsler om en potensiell utfordring eller krever brukerens oppmerksomhet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Critical`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indikerer at noe har gått galt og krever umiddelbar handling (f.eks. sletting).`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Tilstand (state) (hvordan fargen oppfører seg)`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tilstand`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Forklaring`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Default`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Fargens normale, hvilende tilstand uten interaksjon.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Subtle`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`En nedtonet versjon av fargen.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Emphasis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`En mer fremtredende versjon for å trekke oppmerksomhet.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Inverse`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Inverterte roller, f.eks. for tekst på mørke bakgrunner.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Hover`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Når markøren svever over komponenten.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Pressed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Når brukeren klikker eller trykker på komponenten.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Selected`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Indikerer at en komponent er valgt eller aktiv.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Selected-hover`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kombinasjon av selected og hover.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Selected-pressed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kombinasjon av selected og pressed.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Focused	Markerer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`komponenter med tastaturfokus for tilgjengelighet.`}</td>
        </tr>
      </tbody>
    </table>
    <img src={eksempeldesign} alt="eksempel på hvordan fargene brukes i design" className="sb1ds-layout__spacing-bottom--md" />
    <h2>{`Context-laget: Nøkkelen til en robust fargepalett`}</h2>
    <p>{`Farge-tokensene i designsystemet er strukturert i tre lag: `}<em parentName="p">{`Primitive, Semantic og Context.`}</em></p>
    <h3>{`Hvorfor denne strukturen?`}</h3>
    <p>{`I de fleste designsystemer består semantiske farger av to lag:`}</p>
    <ul>
      <li parentName="ul">{`Primitive farger: Grunnfargene.`}</li>
      <li parentName="ul">{`Semantiske farger: Farger knyttet til spesifikke bruksområder (f.eks. "success" eller "warning").`}</li>
    </ul>
    <p>{`Men i vårt design har vi en mørkeblå bakgrunn i lys modus – det vi kaller accent mode.
For at fargene skal fungere både på vanlig lys bakgrunn (default mode) og på mørkeblå bakgrunn (accent mode), måtte vi innføre et tredje lag: context.`}</p>
    <img src={bolgeAccent} alt="bølgen i accent context på mobil og desktop" className="sb1ds-layout__spacing-bottom--md" />
    <h3>{`Hvordan fungerer det?`}</h3>
    <ol>
      <li parentName="ol">{`Primitive-laget inneholder de grunnleggende fargene.`}</li>
      <li parentName="ol">{`Semantic-laget bruker primitive farger og definerer dem for lys og mørk modus.`}</li>
      <li parentName="ol">{`Context-laget sikrer at semantiske farger fungerer riktig på både default mode og accent mode.`}</li>
    </ol>
    <p>{`Dette gir en fleksibel fargepalett som fungerer på tvers av bakgrunner, uten at vi må lage spesialtilpasninger for hver enkelt situasjon.`}</p>
    <p>{`Som designer er det context-laget du hovedsakelig forholder deg til, siden det er disse fargene som er tilgjengelige i Figma. Det semantiske laget håndterer kun vekslingen mellom lys og mørk modus, mens context-laget sikrer at fargene fungerer optimalt på både lys bakgrunn (default mode) og mørkeblå bakgrunn (accent mode).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      